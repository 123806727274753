#hovereffect:hover{
  background-color: rgba(214,214,214, 0.5);
  /* height: 35px;
  width: 35px; */
  border-radius: 17px;
  cursor: pointer;


}

#hovereffect1:hover{
  background-color: rgba(214,214,214, 0.5);
  /* height: 35px;
  width: 35px; */
  border-radius: 50%;
  cursor: pointer;

}

.preview{
 
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    height: 78vh;

}
/* for disable selection */
#AAA1 {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}


/* checkbox remeber me */

.round {
  position: relative;
  margin-left: 4.2%;
  margin-top: 5%;
  
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}


/* login model */
.my-modal {
  width: 90vw;

  max-width: 70vw;
} 

h4{
  padding: 0px;
  margin: 0px;
}


.pdfviewerclass{
  max-width: 100vw;
  min-width: 37vw;
  height: 78vh;
}

.coloreffect1{
  border: thin solid #66bb6a;
  border-radius: 17px;

}
.coloreffect{
  color: #66bb6a;
}
.icolor1{
  color: #66bb6a;
}

/* .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense.css-10ltm04-MuiToolbar-root{
  display: none;
} */
#docpreview{
  display: none;
}
.imgside{
  display: none;
}
.closeicon{
  display: none;
}
.exportdiv{
  margin-right: 10%;
}
.printdiv{
  margin-left: 10%;
}
@media screen and (max-width: 799px) {

  .exportdiv{
    margin-right: 0px;
    text-align: center;
  }
  .printdiv{
    margin-left: 0px;
    text-align: center;
  }
  .copydiv{
    text-align: center;
  }

.btnclass{
  background-color: #71c480;
  /* height: auto; */
  /* height: 100%; */
  /* height: fit-content; */
  /* width: fit-content; */
  height: 40px;
}
#hovereffect{
  color: white;
}
.coloreffect{
  color: white;
  display: contents;
}
.coloreffect1{
  border: none;
  border-radius: 17px;
}
.icolor1{
  color: white;
}
.IMGLOGO{
  display: none;
}
.rightpreview{
  margin-top: 30px;
}
/* .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense.css-10ltm04-MuiToolbar-root{
  display: flex;
} */

#docpreview{
  display: flex;
}
.loginside{
  display: none;
}
.imgside{
  display: flex;
}
.closeicon{
  display: contents;
  color: #71c480;
  /* margin-left: -20px;
  margin-right: 20px; */
}
.preview{
  transform: scale(1,1.1);
  height: 80vh;
}
.round{
  display: -webkit-inline-box;
}

.my-modal {
  margin-top: 8.5%;
  width: 99vw;
  max-width: 95vw;
  height: 100%;
  margin-bottom: 15%;
} 
.fa-times:before{
  margin-left: -20px;
  margin-right: 20px;
}
/* .modal-content {
  margin-top: 7.5%;
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  margin-bottom: 15%;

} */


.pdfviewerclass{
  height: 80vh;
}
.modal-content{
  /* margin-top: 60%; */
  transform: scale(0.9,0.7);
}
/* .signupmodel{
  height: 80%;
} */
}





/* hide scrollbar but allow scrolling document preview */
.horScroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.horScroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}