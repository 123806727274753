@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Open Sans";
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




/* hide scrollbar but allow scrolling */
.verScroll {
  
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: hidden; 
}

.verScroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.center {
  margin-top: -17px;
}
#card {
  width: 100%;
  /* margin: 0 auto; */
  border-radius: 16px;
  background-color: white;
  color: black;
  cursor: auto;
  border-color: #71c480;
  display: inline-block;
  box-shadow: none;
  border: 0.5px solid #71c480;
  border-width: 0.5px;
  /* orientation: centre; */
}
/* .radio1 {
  width: 18px;
  height: 18px;
  /* margin-right: 14px; */
/* visibility: hidden;
  line-height: 24px; 
} */
#contextmenuRB {
  margin-top: 30px;
  color: #71c480;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#heading {
  width: 100%;
  /* text-align: center; */
  color: #71c480;
}
#heading1 {
  /* margin: 0 auto; */
  /* margin-left: auto; */
  /* text-align: right; */
  margin-left: auto;
  color: #71c480;
}
#nameTypo {
  /* font-size: 19px; */
  color: #000000;
  font-family: "Open Sans";
}
#nameTypo1 {
  /* font-size: 19px; */
  color: #000000;
  font-family: "Open Sans";
  /* font-weight: normal; */
}

#icons {
  width: 100%;
  text-align: center;
}
i {
  cursor: pointer;
}
.fa-plus {
  color: white;
  background-color: rgb(0, 0, 0);
  padding: 2px;
}
.hovering:hover {
  opacity: 100;
}
.hovering2:hover {
  opacity: 100;
  color: rgb(165, 163, 163);
}
.hovering {
  padding: 15px;
  opacity: 0;
}
.hovering2 {
  opacity: 0;
}
.fa-times {
  margin-top: 5px;
}
#panel1a-header {
  padding: 0px 24px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.hrtag {
  border-top: 1px solid #71c480;
}
.plushover:hover {
  background-color: rgb(113, 196, 128, 0.1);
}

.badge {
  /* padding: 5px 15px 0 15px; */
  height: 27px;
  font-size: 19px;
  font-family: "Open Sans";
  font-weight: normal;
  color: #000000;
}
#statement {
  /* font-size: 18px; */
  font-family: "Open Sans";
  /* font-weight: normal; */
  color: #000000;
}
nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 14px;
  background-color: rgb(255, 255, 255);
  padding: 2px 0;
  z-index: 3;
  /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.027); */
}
.react-contextmenu .react-contextmenu-item:hover {
  background: #e2e2e2;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  border-radius: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

.signatureDiv {
  width: 280px;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 5px 5px 6px grey;
  border-radius: 10px;
  border-color: #71c480;
}
.SigCard {
  font-size: 19px;
  font-family: "Open Sans" !important;
  font-weight: normal;
  color: #000000;
}

.signatureCanvas {
  border-radius: 10px;
  width: 200px;
  margin: 0 auto;
  height: 80px;
  background-color: #ece8e6;
  z-index: 6;
}
.btn2 {
  margin-bottom: 75px;
  background-color: lightgrey;
  text-align: center;

  justify-content: center;
  border-radius: 10px;
  border: 2px solid black;
  border-color: #000;
}

.btn1:hover {
  background-color: #a37f74;
}
.btn2:hover {
  background-color: grey;
}
.eParent div {
  float: left;
  clear: none;
}
.rotateIcon {
  -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg);
  /* transform: rotateX(-270deg); */
}
.rotate1 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
/* input[type='radio'] {
  position: absolute;
 }
input[type="radio"]:checked:after {
  width: 28px;
  height: 28px;
  border-radius: 15px;
  top: 1px;
  left: 0px;
  position: relative;
  background-color: #71c480;
  content: "";
  display: inline-block;
  visibility: visible;
  color: #71c480;
  border: 2px solid #71c480;
} */

.MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root {
  padding: 0px;
}

p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
  font-family: "Open Sans";
  margin-top: 20px;
}
.form-control {
  padding: 20px 20px;
  /* width: 50% !important; */
  line-height: 30px;
  color: #000000;
}

@media screen and (max-width: 799px) {
  #eyeIcon {
    display: none;
  }
  .plusicon{
    display: none;
  }
  #nameTypo {
    float: right !important;
    color: black !important;
  }
  #nameTypo1 {
    float: right !important;
    color: #000 !important;
  }
  #statement {
    /* font-size: 18px; */
    font-family: "Open Sans";
    /* font-weight: normal; */
    color: #000000 !important;
  }
  #card {
    width: 100%;
    border-radius: 16px;
    box-sizing: border-box;
    /* background-color: #71c480; */
  }
  .badge {
    padding: 5px 15px 0 15px;
    /* font-size: 16px; */
    color: #000000 !important;
    background-color: transparent;
  }

  .Mui-expanded {
    border-radius: 16px !important;
    background-color: white !important;
    border: white;
    border-color: #ffffff !important;
  }

  .MuiTypography-h6 {
    color: #71c480 !important;
  }

  .MuiSvgIcon-root {
    color: #71c480;
  }

  .text-success {
    color: #000 !important;
  }

  #AppUsermenu {
    display: none;
  }

  .MuiButton-label {
    font-size: x-large;
    margin-left: 15px;
  }

  .MuiToolbar-root {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }
}

/* radio button setting coloring and box */

.rad,
.ckb {
  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.rad > input,
.ckb > input {
  /* HIDE ORG RADIO & CHECKBOX */
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
/* RADIO & CHECKBOX STYLES */
/* DEFAULT <i> STYLE */
.rad > i {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: 0.2s;
  border: 1px solid #71c480;
  background: white;
}
/* HOVER <i> STYLE */
.rad:hover > i {
  box-shadow: inset 0 0 0 3px #fff;

  background: gray;
}
.rad > input:checked + i {
  /* (RADIO CHECKED) <i> STYLE */
  background: #71c480;
}
.datee {
  font-size: 19px;
  font-family: "Open Sans";
  font-weight: normal;
  color: #000000;
}
.css-hlnzui-MuiTypography-root {
  font-family: "Open Sans";
}
input::-webkit-datetime-edit {
  color: transparent;
}
input:focus::-webkit-datetime-edit {
  color: #000;
}

html,
body {
  margin: 0;
  background-color: white;
}

#mainPanel {
  display: flexbox;
  min-height: 100vh;
  background-color: white;
  margin-left: 1px;
}

#margin {
  margin: 24px;
  height: 80vh;
  border-radius: 10px;
  outline-color: green;
  border: 2px solid green;
  border-color: green;
  overflow-y: scroll;
  overflow-x: hidden;
}

#leftPanel {
  background-color: white;
  /* margin-top: -3px;
  height: 900px;
  border-radius: 10px;
  outline-color: green;
  border: 2px solid green;
  border-color: green;
  overflow-y: scroll; */
}

#leftPanel2 {
  background-color: white;

  /* height: 900px; */
  /* border: 2px solid green;
  outline-color: green;
  border-color: green;
  border-radius: 10px;
  overflow-y: scroll; */
}

/* .dropdown-menu{
  /* left: 50%;
  right: auto; 
  transform: translateX(-100%);
  border: 2px solid #71c480;
  
} */

/* .dropdown-item{
  color: #71c480;
} */
.gobtn:hover {
  background-color: rgb(113, 196, 128, 0.08);
  /* opacity: 0.8; */
}
#insideLeftpanel {
  height: 85vh;
  /* width: 43.75vw; */
  /* border: 2px solid #71c480; */
  outline-color: #71c480;
  border-radius: 16px;
  margin-top: 80px;
  margin-bottom: 24px;

  margin-right: 24px;
  margin-left: 24px;
  /* margin: 24px; */
  overflow-y: scroll;
  background-color: #f0f6f6;
}

#rightPanel {
  /* margin-top: -3px; */
  height: 83vh;
  margin-top: 80px;
  background-color: white;
  position: relative;
}

#bar {
  height: 4px;
  margin-left: 30px;
}

#verticalBar {
  color: #1b1a1a;
  position: relative;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

#menu {
  width: 90%;
  text-align: center;
  z-index: 10000;
}

#context-menu {
  position: fixed;
  z-index: 10000;
  width: 150px;
  background: #1b1a1a;
  border-radius: 5px;
}

#context-menu .item {
  padding: 8px 10px;
  font-size: 15px;
  color: #eee;
  cursor: pointer;
  border-radius: inherit;
}

#context-menu .item:hover {
  background: #343434;
}

#d1 {
  display: none;
}

#sidebar {
  display: none;
}

.notshow {
  position: fixed;
  z-index: -10;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 2000px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  /* background: green; */
  border-radius: 2000px;
}

::-webkit-scrollbar-track {
  border-radius: 50px;
  -webkit-border-radius: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  border: 20px;
}

div:hover::-webkit-scrollbar-thumb {
  background: #71c480;
}
.swiper-slide.swiper-slide-active {
  width: 43.75%;
}
@media screen and (max-width: 799px) {
  #Printbtn {
    display: none;
  }

  #Pdfbtn {
    display: none;
  }
  .icolor {
    color: #71c480 !important;
  }
  .container1 {
    padding-right: 24px;
  }
  .gobtn {
    color: #71c480 !important;
  }
  .gobtn:hover {
    background-color: rgb(113, 196, 128, 0.08) !important;
    /* opacity: 0.8; */
  }
  #Notificationbtn {
    display: none;
  }

  #previewbtn {
    display: none;
  }

  /* #exportBtn {
    display: none;
  } */

  #penBtn {
    display: none;
  }

  #clockBtn {
    display: none;
  }

  #documentPreview {
    display: none;
  }

  header.MuiPaper-root button[aria-label="Refresh"] {
    display: none;
  }

  #insideLeftpanel {
    height: 80vh;
    overflow-y: scroll;
    border: none;
    background-color: #f0f6f6;
    /* margin: -2px; */
  }

  .MuiButtonBase-root {
    padding: none;
  }

  .container {
    margin-left: 0px auto;
    margin-right: 0px auto;

    margin-top: 20px;
  }

  #sidebar {
    display: flex !important;
    float: right;
    margin-right: 0;
  }

  #d1 {
    display: flex;
  }
}

#togglebtn {
  width: 16vw;
  margin: 0 auto;
  border: none;
  background: none;
}

.swiper-container {
  width: 100%;
}

@media (max-width: 768px) {
  .swiper-container {
    height: 84vh;
  }
}

.swiper-wrapper {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}
#hovereffect:hover {
  background-color: rgba(214, 214, 214, 0.5);
  /* height: 35px;
  width: 35px; */
  border-radius: 50%;
  cursor: pointer;
}

@media screen {
  #printlogo {
    display: flex;
  }
}
@media print {
  #printlogo {
    display: flex;
  }
}
.di1 {
  display: contents;
}
@media (min-width: 992px) {
  #ls {
    width: 43.75%;
  }
  #rs {
    width: 56.25%;
  }
}

@media (min-width: 768px) {
  #ls {
    width: 43.75%;
  }
  #rs {
    width: 56.25%;
  }
}

#hovereffect:hover{
  background-color: rgba(214,214,214, 0.5);
  /* height: 35px;
  width: 35px; */
  border-radius: 17px;
  cursor: pointer;


}

#hovereffect1:hover{
  background-color: rgba(214,214,214, 0.5);
  /* height: 35px;
  width: 35px; */
  border-radius: 50%;
  cursor: pointer;

}

.preview{
 
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    height: 78vh;

}
/* for disable selection */
#AAA1 {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}


/* checkbox remeber me */

.round {
  position: relative;
  margin-left: 4.2%;
  margin-top: 5%;
  
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}


/* login model */
.my-modal {
  width: 90vw;

  max-width: 70vw;
} 

h4{
  padding: 0px;
  margin: 0px;
}


.pdfviewerclass{
  max-width: 100vw;
  min-width: 37vw;
  height: 78vh;
}

.coloreffect1{
  border: thin solid #66bb6a;
  border-radius: 17px;

}
.coloreffect{
  color: #66bb6a;
}
.icolor1{
  color: #66bb6a;
}

/* .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense.css-10ltm04-MuiToolbar-root{
  display: none;
} */
#docpreview{
  display: none;
}
.imgside{
  display: none;
}
.closeicon{
  display: none;
}
.exportdiv{
  margin-right: 10%;
}
.printdiv{
  margin-left: 10%;
}
@media screen and (max-width: 799px) {

  .exportdiv{
    margin-right: 0px;
    text-align: center;
  }
  .printdiv{
    margin-left: 0px;
    text-align: center;
  }
  .copydiv{
    text-align: center;
  }

.btnclass{
  background-color: #71c480;
  /* height: auto; */
  /* height: 100%; */
  /* height: fit-content; */
  /* width: fit-content; */
  height: 40px;
}
#hovereffect{
  color: white;
}
.coloreffect{
  color: white;
  display: contents;
}
.coloreffect1{
  border: none;
  border-radius: 17px;
}
.icolor1{
  color: white;
}
.IMGLOGO{
  display: none;
}
.rightpreview{
  margin-top: 30px;
}
/* .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense.css-10ltm04-MuiToolbar-root{
  display: flex;
} */

#docpreview{
  display: flex;
}
.loginside{
  display: none;
}
.imgside{
  display: flex;
}
.closeicon{
  display: contents;
  color: #71c480;
  /* margin-left: -20px;
  margin-right: 20px; */
}
.preview{
  -webkit-transform: scale(1,1.1);
          transform: scale(1,1.1);
  height: 80vh;
}
.round{
  display: -webkit-inline-box;
}

.my-modal {
  margin-top: 8.5%;
  width: 99vw;
  max-width: 95vw;
  height: 100%;
  margin-bottom: 15%;
} 
.fa-times:before{
  margin-left: -20px;
  margin-right: 20px;
}
/* .modal-content {
  margin-top: 7.5%;
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  margin-bottom: 15%;

} */


.pdfviewerclass{
  height: 80vh;
}
.modal-content{
  /* margin-top: 60%; */
  -webkit-transform: scale(0.9,0.7);
          transform: scale(0.9,0.7);
}
/* .signupmodel{
  height: 80%;
} */
}





/* hide scrollbar but allow scrolling document preview */
.horScroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.horScroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
