.center {
  margin-top: -17px;
}
#card {
  width: 100%;
  /* margin: 0 auto; */
  border-radius: 16px;
  background-color: white;
  color: black;
  cursor: auto;
  border-color: #71c480;
  display: inline-block;
  box-shadow: none;
  border: 0.5px solid #71c480;
  border-width: 0.5px;
  /* orientation: centre; */
}
/* .radio1 {
  width: 18px;
  height: 18px;
  /* margin-right: 14px; */
/* visibility: hidden;
  line-height: 24px; 
} */
#contextmenuRB {
  margin-top: 30px;
  color: #71c480;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#heading {
  width: 100%;
  /* text-align: center; */
  color: #71c480;
}
#heading1 {
  /* margin: 0 auto; */
  /* margin-left: auto; */
  /* text-align: right; */
  margin-left: auto;
  color: #71c480;
}
#nameTypo {
  /* font-size: 19px; */
  color: #000000;
  font-family: "Open Sans";
}
#nameTypo1 {
  /* font-size: 19px; */
  color: #000000;
  font-family: "Open Sans";
  /* font-weight: normal; */
}

#icons {
  width: 100%;
  text-align: center;
}
i {
  cursor: pointer;
}
.fa-plus {
  color: white;
  background-color: rgb(0, 0, 0);
  padding: 2px;
}
.hovering:hover {
  opacity: 100;
}
.hovering2:hover {
  opacity: 100;
  color: rgb(165, 163, 163);
}
.hovering {
  padding: 15px;
  opacity: 0;
}
.hovering2 {
  opacity: 0;
}
.fa-times {
  margin-top: 5px;
}
#panel1a-header {
  padding: 0px 24px;
  min-height: fit-content;
}
.hrtag {
  border-top: 1px solid #71c480;
}
.plushover:hover {
  background-color: rgb(113, 196, 128, 0.1);
}

.badge {
  /* padding: 5px 15px 0 15px; */
  height: 27px;
  font-size: 19px;
  font-family: "Open Sans";
  font-weight: normal;
  color: #000000;
}
#statement {
  /* font-size: 18px; */
  font-family: "Open Sans";
  /* font-weight: normal; */
  color: #000000;
}
nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 14px;
  background-color: rgb(255, 255, 255);
  padding: 2px 0;
  z-index: 3;
  /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.027); */
}
.react-contextmenu .react-contextmenu-item:hover {
  background: #e2e2e2;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  border-radius: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

.signatureDiv {
  width: 280px;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 5px 5px 6px grey;
  border-radius: 10px;
  border-color: #71c480;
}
.SigCard {
  font-size: 19px;
  font-family: "Open Sans" !important;
  font-weight: normal;
  color: #000000;
}

.signatureCanvas {
  border-radius: 10px;
  width: 200px;
  margin: 0 auto;
  height: 80px;
  background-color: #ece8e6;
  z-index: 6;
}
.btn2 {
  margin-bottom: 75px;
  background-color: lightgrey;
  text-align: center;

  justify-content: center;
  border-radius: 10px;
  border: 2px solid black;
  border-color: #000;
}

.btn1:hover {
  background-color: #a37f74;
}
.btn2:hover {
  background-color: grey;
}
.eParent div {
  float: left;
  clear: none;
}
.rotateIcon {
  transform: rotate(-270deg);
  /* transform: rotateX(-270deg); */
}
.rotate1 {
  transform: rotate(180deg);
}
/* input[type='radio'] {
  position: absolute;
 }
input[type="radio"]:checked:after {
  width: 28px;
  height: 28px;
  border-radius: 15px;
  top: 1px;
  left: 0px;
  position: relative;
  background-color: #71c480;
  content: "";
  display: inline-block;
  visibility: visible;
  color: #71c480;
  border: 2px solid #71c480;
} */

.MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root {
  padding: 0px;
}

p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
  font-family: "Open Sans";
  margin-top: 20px;
}
.form-control {
  padding: 20px 20px;
  /* width: 50% !important; */
  line-height: 30px;
  color: #000000;
}

@media screen and (max-width: 799px) {
  #eyeIcon {
    display: none;
  }
  .plusicon{
    display: none;
  }
  #nameTypo {
    float: right !important;
    color: black !important;
  }
  #nameTypo1 {
    float: right !important;
    color: #000 !important;
  }
  #statement {
    /* font-size: 18px; */
    font-family: "Open Sans";
    /* font-weight: normal; */
    color: #000000 !important;
  }
  #card {
    width: 100%;
    border-radius: 16px;
    box-sizing: border-box;
    /* background-color: #71c480; */
  }
  .badge {
    padding: 5px 15px 0 15px;
    /* font-size: 16px; */
    color: #000000 !important;
    background-color: transparent;
  }

  .Mui-expanded {
    border-radius: 16px !important;
    background-color: white !important;
    border: white;
    border-color: #ffffff !important;
  }

  .MuiTypography-h6 {
    color: #71c480 !important;
  }

  .MuiSvgIcon-root {
    color: #71c480;
  }

  .text-success {
    color: #000 !important;
  }

  #AppUsermenu {
    display: none;
  }

  .MuiButton-label {
    font-size: x-large;
    margin-left: 15px;
  }

  .MuiToolbar-root {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }
}

/* radio button setting coloring and box */

.rad,
.ckb {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.rad > input,
.ckb > input {
  /* HIDE ORG RADIO & CHECKBOX */
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
/* RADIO & CHECKBOX STYLES */
/* DEFAULT <i> STYLE */
.rad > i {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: 0.2s;
  border: 1px solid #71c480;
  background: white;
}
/* HOVER <i> STYLE */
.rad:hover > i {
  box-shadow: inset 0 0 0 3px #fff;

  background: gray;
}
.rad > input:checked + i {
  /* (RADIO CHECKED) <i> STYLE */
  background: #71c480;
}
.datee {
  font-size: 19px;
  font-family: "Open Sans";
  font-weight: normal;
  color: #000000;
}
.css-hlnzui-MuiTypography-root {
  font-family: "Open Sans";
}
input::-webkit-datetime-edit {
  color: transparent;
}
input:focus::-webkit-datetime-edit {
  color: #000;
}
