html,
body {
  margin: 0;
  background-color: white;
}

#mainPanel {
  display: flexbox;
  min-height: 100vh;
  background-color: white;
  margin-left: 1px;
}

#margin {
  margin: 24px;
  height: 80vh;
  border-radius: 10px;
  outline-color: green;
  border: 2px solid green;
  border-color: green;
  overflow-y: scroll;
  overflow-x: hidden;
}

#leftPanel {
  background-color: white;
  /* margin-top: -3px;
  height: 900px;
  border-radius: 10px;
  outline-color: green;
  border: 2px solid green;
  border-color: green;
  overflow-y: scroll; */
}

#leftPanel2 {
  background-color: white;

  /* height: 900px; */
  /* border: 2px solid green;
  outline-color: green;
  border-color: green;
  border-radius: 10px;
  overflow-y: scroll; */
}

/* .dropdown-menu{
  /* left: 50%;
  right: auto; 
  transform: translateX(-100%);
  border: 2px solid #71c480;
  
} */

/* .dropdown-item{
  color: #71c480;
} */
.gobtn:hover {
  background-color: rgb(113, 196, 128, 0.08);
  /* opacity: 0.8; */
}
#insideLeftpanel {
  height: 85vh;
  /* width: 43.75vw; */
  /* border: 2px solid #71c480; */
  outline-color: #71c480;
  border-radius: 16px;
  margin-top: 80px;
  margin-bottom: 24px;

  margin-right: 24px;
  margin-left: 24px;
  /* margin: 24px; */
  overflow-y: scroll;
  background-color: #f0f6f6;
}

#rightPanel {
  /* margin-top: -3px; */
  height: 83vh;
  margin-top: 80px;
  background-color: white;
  position: relative;
}

#bar {
  height: 4px;
  margin-left: 30px;
}

#verticalBar {
  color: #1b1a1a;
  position: relative;
  transform: rotate(90deg);
}

#menu {
  width: 90%;
  text-align: center;
  z-index: 10000;
}

#context-menu {
  position: fixed;
  z-index: 10000;
  width: 150px;
  background: #1b1a1a;
  border-radius: 5px;
}

#context-menu .item {
  padding: 8px 10px;
  font-size: 15px;
  color: #eee;
  cursor: pointer;
  border-radius: inherit;
}

#context-menu .item:hover {
  background: #343434;
}

#d1 {
  display: none;
}

#sidebar {
  display: none;
}

.notshow {
  position: fixed;
  z-index: -10;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 2000px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  /* background: green; */
  border-radius: 2000px;
}

::-webkit-scrollbar-track {
  border-radius: 50px;
  -webkit-border-radius: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  border: 20px;
}

div:hover::-webkit-scrollbar-thumb {
  background: #71c480;
}
.swiper-slide.swiper-slide-active {
  width: 43.75%;
}
@media screen and (max-width: 799px) {
  #Printbtn {
    display: none;
  }

  #Pdfbtn {
    display: none;
  }
  .icolor {
    color: #71c480 !important;
  }
  .container1 {
    padding-right: 24px;
  }
  .gobtn {
    color: #71c480 !important;
  }
  .gobtn:hover {
    background-color: rgb(113, 196, 128, 0.08) !important;
    /* opacity: 0.8; */
  }
  #Notificationbtn {
    display: none;
  }

  #previewbtn {
    display: none;
  }

  /* #exportBtn {
    display: none;
  } */

  #penBtn {
    display: none;
  }

  #clockBtn {
    display: none;
  }

  #documentPreview {
    display: none;
  }

  header.MuiPaper-root button[aria-label="Refresh"] {
    display: none;
  }

  #insideLeftpanel {
    height: 80vh;
    overflow-y: scroll;
    border: none;
    background-color: #f0f6f6;
    /* margin: -2px; */
  }

  .MuiButtonBase-root {
    padding: none;
  }

  .container {
    margin-left: 0px auto;
    margin-right: 0px auto;

    margin-top: 20px;
  }

  #sidebar {
    display: flex !important;
    float: right;
    margin-right: 0;
  }

  #d1 {
    display: flex;
  }
}

#togglebtn {
  width: 16vw;
  margin: 0 auto;
  border: none;
  background: none;
}

.swiper-container {
  width: 100%;
}

@media (max-width: 768px) {
  .swiper-container {
    height: 84vh;
  }
}

.swiper-wrapper {
  padding-inline-start: 0;
}
#hovereffect:hover {
  background-color: rgba(214, 214, 214, 0.5);
  /* height: 35px;
  width: 35px; */
  border-radius: 50%;
  cursor: pointer;
}

@media screen {
  #printlogo {
    display: flex;
  }
}
@media print {
  #printlogo {
    display: flex;
  }
}
.di1 {
  display: contents;
}
@media (min-width: 992px) {
  #ls {
    width: 43.75%;
  }
  #rs {
    width: 56.25%;
  }
}

@media (min-width: 768px) {
  #ls {
    width: 43.75%;
  }
  #rs {
    width: 56.25%;
  }
}
