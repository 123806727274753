@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Open Sans";
}
